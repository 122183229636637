import * as React from 'react'
import { Locale, StaticTranslations, DynamicTranslations } from './util'
import { I18nContext, Consumer as I18nContextConsumer } from './I18nContext'

type Translations = StaticTranslations | DynamicTranslations

type RenderComponentReturnType = ReturnType<React.StatelessComponent>

export type I18nProps<L extends Locale, T extends Translations> = {
  readonly children: (i18n: I18nContext<L, T>) => RenderComponentReturnType
}

export const I18n = <L extends Locale, T extends Translations>(
  props: I18nProps<L, T>
) => {
  const { children: renderChildren } = props

  return (
    <I18nContextConsumer>
      {(i18n) => renderChildren(i18n as I18nContext<L, T>)}
    </I18nContextConsumer>
  )
}
