import * as React from 'react'
import {
  Locale,
  Language,
  StaticTranslations,
  DynamicTranslations,
  GetDynamicTranslation,
  GetStaticTranslation,
  getLanguageFromLocale,
  isRtlLanguage,
  defineStaticTranslationGetter,
  defineDynamicTranslationGetter,
} from './util'
import { I18nContext, Provider as I18nContextProvider } from './I18nContext'

type ObjectOmit<
  R extends Readonly<Record<PropertyKey, unknown>>,
  K extends keyof R
> = Pick<R, Exclude<keyof R, K>>

type Translations = StaticTranslations | DynamicTranslations

type TranslationValue =
  | StaticTranslations[any]
  | DynamicTranslations[any]
  | undefined

export type I18nProviderState<L extends Locale, T extends Translations> = {
  readonly context: ObjectOmit<I18nContext<L, T>, 't'> &
    Readonly<{
      t:
        | GetDynamicTranslation<DynamicTranslations>
        | GetStaticTranslation<StaticTranslations>
    }>
  readonly prevContextOptions: ContextOptions<L, T>
}

export type I18nProviderProps<L extends Locale, T extends Translations> = {
  readonly locale: L
  readonly translations: T
  readonly rtlLanguages?: ReadonlyArray<Language>
  readonly children: React.ReactNode
}

type I18nProviderGenericProps<
  L extends Locale,
  T extends Translations
> = ObjectOmit<I18nProviderProps<L, T>, 'translations'> &
  Readonly<{ translations: Translations }>

type ContextOptions<L extends Locale, T extends Translations> = ObjectOmit<
  I18nProviderGenericProps<L, T>,
  'children'
>

const getFirstTranslationValue = <T extends Translations>(
  translations: T
): TranslationValue => {
  let translationValue: T[keyof T] | undefined

  for (const i in translations) {
    if (translations.hasOwnProperty(i)) {
      translationValue = translations[i]
      break
    }
  }

  return translationValue
}

const hasStaticTranslations = <T extends Translations>(
  translationValue: TranslationValue,
  _translations: T
): _translations is Exclude<T, DynamicTranslations> => {
  return typeof translationValue !== 'function'
}

const hasDynamicTranslations = <T extends Translations>(
  translationValue: TranslationValue,
  _translations: T
): _translations is Exclude<T, StaticTranslations> => {
  return typeof translationValue === 'function'
}

const pickContextOptions = <L extends Locale, T extends Translations>(
  props: I18nProviderGenericProps<L, T> | ContextOptions<L, T>
): ContextOptions<L, T> => {
  const options: {
    [K in keyof Required<ContextOptions<L, T>>]: ContextOptions<L, T>[K]
  } = {
    locale: props.locale,
    translations: props.translations,
    rtlLanguages: props.rtlLanguages,
  }

  return options
}

const shouldUpdateContext = <L extends Locale, T extends Translations>(
  props: I18nProviderGenericProps<L, T> | ContextOptions<L, T>,
  prevProps: typeof props
): boolean => {
  const options = pickContextOptions<L, T>(props)
  const prevOptions = pickContextOptions<L, T>(prevProps)

  const optionKeys = Object.keys(options) as ReadonlyArray<keyof typeof options>
  const prevOptionKeys = Object.keys(prevOptions) as ReadonlyArray<
    keyof typeof prevOptions
  >

  if (optionKeys.length !== prevOptionKeys.length) {
    return true
  }

  for (const optionKey of optionKeys) {
    const option = options[optionKey]
    const prevOption = prevOptions[optionKey]

    if (option !== prevOption) {
      return true
    }
  }

  return false
}

const getContext = <L extends Locale, T extends Translations>(
  options: ContextOptions<L, T>
): I18nContext<L, T> => {
  const { locale, translations, rtlLanguages } = options

  const language = getLanguageFromLocale(locale)

  const isLanguageRtl = isRtlLanguage(language, rtlLanguages)

  const translationValue = getFirstTranslationValue(translations)

  let context: I18nContext<L, Translations>

  if (hasDynamicTranslations(translationValue, translations)) {
    context = {
      locale,
      language,
      isLanguageRtl,
      translations,
      t: defineDynamicTranslationGetter({
        translations,
      }),
    }
  } else if (hasStaticTranslations(translationValue, translations)) {
    context = {
      locale,
      language,
      isLanguageRtl,
      translations,
      t: defineStaticTranslationGetter({
        locale,
        translations,
      }),
    }
  } else {
    context = {
      locale,
      language,
      isLanguageRtl,
      translations: translations as StaticTranslations,
      t: defineStaticTranslationGetter<L, StaticTranslations>({
        locale,
        translations,
      }),
    }
  }

  return context as I18nContext<L, T>
}

export class I18nProvider<
  L extends Locale,
  T extends Translations
> extends React.Component<I18nProviderProps<L, T>, I18nProviderState<L, T>> {
  static getDerivedStateFromProps<L extends Locale, T extends Translations>(
    props: I18nProviderProps<L, T>,
    state: I18nProviderState<L, T>
  ): I18nProviderState<L, T> | null {
    const { prevContextOptions } = state

    if (shouldUpdateContext(props, prevContextOptions)) {
      return {
        context: getContext(props),
        prevContextOptions: pickContextOptions(props),
      }
    }

    return null
  }

  readonly state: I18nProviderState<L, T> = {
    context: getContext(this.props),
    prevContextOptions: pickContextOptions(this.props),
  }

  render() {
    return (
      <I18nContextProvider value={this.state.context}>
        {this.props.children}
      </I18nContextProvider>
    )
  }
}
