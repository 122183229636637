type ParamType<
  F extends (() => any) | ((arg: any) => any)
> = F extends () => any ? never : (F extends (arg: infer P) => any ? P : never)

declare class OpaqueTag<S extends symbol> {
  private readonly tag: S
}

declare const LocaleTag: unique symbol
export type Locale = string & OpaqueTag<typeof LocaleTag>

declare const LanguageTag: unique symbol
export type Language = string & OpaqueTag<typeof LanguageTag>

export type StaticTranslations = { readonly [K in string | number]: string }

export type DynamicTranslations = {
  readonly [K in string | number]: (() => string) | ((variables: any) => string)
}

// TODO: Consider replacing `{}` value type with `unknown` or `any`
export type StaticTranslationVariables = { readonly [K in string | number]: {} }

export type DynamicTranslationVariables<
  T extends DynamicTranslations,
  K extends keyof T
> = ParamType<T[K]>

export type GetStaticTranslationOptions<
  T extends StaticTranslations,
  K extends keyof T
> = {
  readonly key: K
  readonly variables?: StaticTranslationVariables
}

export type GetDynamicTranslationOptions<
  T extends DynamicTranslations,
  K extends keyof T
> = DynamicTranslationVariables<T, K> extends never
  ? {
      readonly key: K
      readonly variables?: undefined
    }
  : {
      readonly key: K
      readonly variables: DynamicTranslationVariables<T, K>
    }

export type GetStaticTranslation<T extends StaticTranslations> = <
  K extends keyof T
>(
  options: GetStaticTranslationOptions<T, K>
) => string

export type GetDynamicTranslation<T extends DynamicTranslations> = <
  K extends keyof T
>(
  options: GetDynamicTranslationOptions<T, K>
) => string

export const isLocale = <L extends Locale>(value: unknown): value is L => {
  // tslint:disable-next-line strict-type-predicates
  if (typeof value !== 'string' || !/^[a-zA-Z0-9-]+$/.test(value)) {
    return false
  }

  // tslint:disable-next-line strict-type-predicates
  if (typeof Intl !== 'undefined' && typeof Intl.Collator !== 'undefined') {
    try {
      // tslint:disable-next-line no-unused-expression
      new Intl.Collator(value)
      return true
    } catch (error) {
      if (error instanceof RangeError) {
        return false
      } else {
        throw error
      }
    }
  }

  return true
}

export const assertLocale = <L extends Locale>(value: unknown): L => {
  if (!isLocale<L>(value)) {
    throw new Error(`Invalid locale ${value}.`)
  }
  return value
}

export const isLanguage = (value: unknown): value is Language => {
  // tslint:disable-next-line strict-type-predicates
  return typeof value === 'string' && /^[a-zA-Z]+$/.test(value)
}

export const assertLanguage = (value: unknown): Language => {
  if (!isLanguage(value)) {
    throw new Error(`Invalid language ${value}.`)
  }
  return value
}

export const RTL_LANGUAGES: ReadonlyArray<Language> = [
  'ae', // Avestan
  'ar', // 'العربية', Arabic
  'arc', // Aramaic
  'bcc', // 'بلوچی مکرانی', Southern Balochi
  'bqi', // 'بختياري', Bakthiari
  'ckb', // 'Soranî / کوردی', Sorani
  'dv', // Dhivehi
  'fa', // 'فارسی', Persian
  'glk', // 'گیلکی', Gilaki
  'he', // 'עברית', Hebrew
  'ku', // 'Kurdî / كوردی', Kurdish
  'mzn', // 'مازِرونی', Mazanderani
  'nqo', // N'Ko
  'pnb', // 'پنجابی', Western Punjabi
  'ps', // 'پښتو', Pashto
  'sd', // 'سنڌي', Sindhi
  'ug', // 'Uyghurche / ئۇيغۇرچە', Uyghur
  'ur', // 'اردو', Urdu
  'yi', // 'ייִדיש', Yiddish
].map(assertLanguage)

const PLACEHOLDER_PREFIX = '{'

const PLACEHOLDER_SUFFIX = '}'

export const getLanguageFromLocale = <L extends Locale>(
  locale: L
): Language => {
  return locale.split('-')[0] as Language
}

export const isRtlLanguage = (
  language: Language,
  rtlLanguages: ReadonlyArray<Language> = RTL_LANGUAGES
): boolean => {
  return rtlLanguages.includes(language)
}

export const formatTranslation = (
  options: Readonly<{
    variables: Readonly<Record<string | number, unknown>>
    placeholderPrefix?: string
    placeholderSuffix?: string
  }>,
  translation: string
): string => {
  const {
    variables,
    placeholderPrefix = PLACEHOLDER_PREFIX,
    placeholderSuffix = PLACEHOLDER_SUFFIX,
  } = options

  return Object.entries(variables).reduce(
    (acc, [key, value]: [string | number, unknown]) => {
      return acc.replace(
        new RegExp(
          `(${placeholderPrefix}${key}${placeholderSuffix}|\\\\${placeholderPrefix}${key}\\\\${placeholderSuffix})`,
          'g'
        ),
        (match) => {
          return match.startsWith(`\\${placeholderPrefix}`)
            ? `${placeholderPrefix}${key}${placeholderSuffix}`
            : String(value)
        }
      )
    },
    translation
  )
}

export const defineStaticTranslationGetter = <
  L extends Locale,
  T extends StaticTranslations
>(
  settings: Readonly<{
    locale: L
    translations: T
  }>
): GetStaticTranslation<T> => {
  const { locale, translations } = settings

  const getTranslation = (
    options: GetStaticTranslationOptions<T, keyof T>
  ): string => {
    const { key } = options

    if (!(key in translations)) {
      console.warn(
        `Missing translation for key "${key}" in locale "${locale}".`
      )
      return String(key)
    }

    return translations[key]
  }

  return getTranslation
}

export const defineDynamicTranslationGetter = <T extends DynamicTranslations>(
  settings: Readonly<{
    translations: T
  }>
): GetDynamicTranslation<T> => {
  const { translations } = settings

  const getTranslation = (
    options: Readonly<{
      key: keyof T
      variables?: DynamicTranslationVariables<T, keyof T>
    }>
  ): string => {
    const { key, variables } = options

    const getter: (variables: typeof options['variables']) => string =
      translations[key]

    return getter(variables)
  }

  return getTranslation
}
