import * as React from 'react'
import {
  Locale,
  Language,
  StaticTranslations,
  DynamicTranslations,
  GetStaticTranslation,
  GetDynamicTranslation,
  assertLocale,
  getLanguageFromLocale,
  isRtlLanguage,
  defineStaticTranslationGetter,
} from './util'

type Translations = StaticTranslations | DynamicTranslations

export type I18nContext<L extends Locale, T extends Translations> = {
  readonly locale: L
  readonly language: Language
  readonly isLanguageRtl: boolean
  readonly translations: T
  readonly t: T extends DynamicTranslations
    ? GetDynamicTranslation<Exclude<T, StaticTranslations>>
    : GetStaticTranslation<Exclude<T, DynamicTranslations>>
}

const LOCALE = assertLocale('en-US')

const LANGUAGE = getLanguageFromLocale(LOCALE)

const CONTEXT: I18nContext<Locale, StaticTranslations> = {
  locale: LOCALE,
  language: LANGUAGE,
  isLanguageRtl: isRtlLanguage(LANGUAGE),
  translations: {},
  t: defineStaticTranslationGetter<Locale, StaticTranslations>({
    locale: LOCALE,
    translations: {},
  }),
}

const { Provider, Consumer } = React.createContext<
  I18nContext<Locale, Translations>
>(CONTEXT)

export { Provider, Consumer }
